import { pieArcLabelClasses, PieChart } from '@mui/x-charts/PieChart';
import React from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateCampaign } from "../campaigns/campaignSlice";
import { updateUser } from '../users/userSlice';
export default function PieChartWithPercentage({ type, data }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    console.log("data", data)
    const res = data?.map((v) => {
        return { label: v.status, value: v.count }
    });

    const validData = Array.isArray(res) ? res : [];

    const total = validData.reduce((sum, item) => sum + item.value, 0);

    const handleClick = (event, params) => {
        let data1 = res?.map((v) => v.label);
        let status = data1[params.dataIndex]?.charAt(0)?.toUpperCase() + data1[params.dataIndex]?.slice(1)?.toLowerCase()

        if (type == 1) {
            dispatch(updateCampaign({ routing: "audit", filters: { status: status } }));
            navigate("/campaign-management");
        } else {
            dispatch(updateUser({ filters: { status: status } }));
            navigate("/userManagement");
        }


    };
    return (
        <PieChart
            series={[
                {
                    data: validData,
                    arcLabel: (item) =>
                        ` ${(item.value / total * 100).toFixed(2)}%`, // Display label with percentage
                },
            ]}
            sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                    fontWeight: 'bold',
                    fontSize: 10,
                    fill: 'white',
                },
            }}
            height={300}
            onItemClick={handleClick}
        />
    );
}
