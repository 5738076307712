import CloseIcon from '@mui/icons-material/Close';
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow,
    Typography
} from '@mui/material';
import React, { useState } from 'react';
import { getFormatedDate, maskCardNumber } from '../../utils/common';

export default function UserProfileDialog({ open, handleClose, data }) {
    const [modelOpen, setModelOpen] = useState(false);
    const [history, setHistory] = useState({ name: "", data: [] })

    const [imgapreview, setImg] = useState({ image: "", status: false })

    const Details = ({ history, name, setImg }) => {
        return <TableContainer stickyHeader >
            <Table stickyHeader aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left">Date</TableCell>
                        <TableCell align="left">Reason</TableCell>
                        <TableCell align="left">Status</TableCell>
                    </TableRow>
                </TableHead>
                {console.log("history?.attempts", history?.attempts)}
                <TableBody>
                    {history?.attempts?.length > 0 ? history?.attempts?.map((v) => {
                        let img = v.document;
                        let img2 = null;
                        if (v.docType === "AADHAR") {
                            const image = JSON.parse(v.document) || [];
                            img = image.length > 0 ? image[0] : "/";
                            img2 = image.length > 0 ? image[1] : "/";
                        }
                        return <TableRow >
                            <TableCell align="left" className="capitalize-first-letter">
                                <Stack direction={"column"} gap={2}>
                                    <img src={img} onClick={() => setImg({ image: img, status: true })} alt={name} style={{ width: 100, height: 50, borderRadius: 2, cursor: "pointer" }} />
                                    {img2 && <img src={img2} onClick={() => setImg({ image: img2, status: true })} alt={name} style={{ width: 100, height: 50, borderRadius: 2, cursor: "pointer" }} />}
                                </Stack>
                            </TableCell>
                            <TableCell align="left" className="capitalize-first-letter">
                                {getFormatedDate(v.createdDate, "DD-MM-YYYY hh:mmA")}
                            </TableCell>
                            <TableCell align="left" >{v.reason} </TableCell>
                            <TableCell align="left"> <button className={v.status?.toLowerCase() + " capitalize-first-letter"}>{v.status}</button> </TableCell>
                        </TableRow>
                    }) : <TableRow colSpan={5}>
                        <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                            <Typography
                                color="inherit"
                                variant="subtitle1"
                                alignItems={"center"}
                                component="h3"
                            >
                                No Data found!
                            </Typography>

                        </TableCell>
                    </TableRow>}


                </TableBody>
            </Table>
        </TableContainer>
    }

    return (
        <>
            <Dialog
                onClose={handleClose}
                aria-labelledby="custom-dialog-title"
                open={open}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle sx={{ m: 0, }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" className="capitalize-first-letter">
                            {data?.name} &emsp;

                            {/* <Chip variant='contained' size="small" color='success' label="Approved" /> */}
                            <button className={data.status?.toLowerCase() + " capitalize-first-letter"}>{data.status}</button>
                        </Typography>
                        <Grid item>

                            <IconButton
                                onClick={handleClose}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: '#000',
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent sx={{ p: 0 }} dividers>
                    <Grid container spacing={2} p={2}>
                        <Grid item md={3} lg={3} sm={12} xs={12}>
                            <Avatar
                                alt={data?.name}
                                src={data?.selfie} // Replace with actual image path
                                sx={{ width: 100, height: 100, borderRadius: 2 }}
                            />
                        </Grid>
                        <Grid item md={9} lg={9} sm={12} xs={12} >
                            <Grid container spacing={1} >
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <Typography variant="body1">Full Name:</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={9} lg={9}>
                                    <Typography variant="body1" className="capitalize-first-letter"><b>{data?.name}</b></Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <Typography variant="body1">Date of Birth:</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={9} lg={9}>
                                    <Typography variant="body1" ><b>{data.dob || "N/A"}</b></Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <Typography variant="body1">Address:</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={9} lg={9}>
                                    <Typography variant="body1">
                                        <b>{data.address + " " + data.city + " " + data.state}</b>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Table sx={{ mt: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Items</TableCell>
                                <TableCell align="left">Action</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>PAN ({maskCardNumber(data.panNumber)})</TableCell>
                                <TableCell>
                                    <span className="viewIcon" onClick={() => {
                                        setModelOpen(true);
                                        setHistory({ name: "PAN History", data: data?.files?.find((v) => v.docType === "PAN") })
                                    }}>View History</span>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Aadhaar ({maskCardNumber(data.aadharNumber)})</TableCell>
                                <TableCell>
                                    <span className="viewIcon" onClick={() => {
                                        setModelOpen(true);
                                        setHistory({ name: "Aadhaar History", data: data?.files?.find((v) => v.docType === "AADHAR") })
                                    }}>View History</span>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Selfie</TableCell>
                                <TableCell >
                                    <span className="viewIcon" onClick={() => {
                                        setModelOpen(true);
                                        setHistory({ name: "Selfie History", data: data?.files?.find((v) => v.docType === "SELFIE") })
                                    }} >View History</span>
                                </TableCell>
                            </TableRow>


                        </TableBody>
                    </Table>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} variant='contained' sx={{ bgcolor: "gray" }} >Cancel</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                onClose={() => setModelOpen(false)}
                aria-labelledby="custom-dialog-title"
                open={modelOpen}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle sx={{ m: 0, }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" className="capitalize-first-letter">
                            {history.name} &emsp;
                        </Typography>
                        <Grid item>

                            <IconButton
                                onClick={() => setModelOpen(false)}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: '#000',
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent sx={{ p: 0 }} dividers>

                    <Details history={history.data} name={history.name} setImg={setImg} />
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setModelOpen(false)} variant='contained' sx={{ bgcolor: "gray" }} >Cancel</Button>
                </DialogActions>
            </Dialog>


            <Dialog
                onClose={() => setImg((prev) => ({ ...prev, status: false }))}
                aria-labelledby="custom-dialog-title"
                open={imgapreview.status}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle sx={{ m: 0, }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" className="capitalize-first-letter">
                            Image &emsp;
                        </Typography>
                        <Grid item>

                            <IconButton
                                onClick={() => setImg((prev) => ({ ...prev, status: false }))}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: '#000',
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                {console.log("imgapreview", imgapreview)}
                <DialogContent
                    sx={{
                        p: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                    }}
                    dividers
                >
                    <Box
                        width="100%"
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <img
                            src={imgapreview.image}
                            style={{
                                width: "auto",  // Use auto for the width or height to maintain aspect ratio
                                height: "auto",
                                maxWidth: "100%",  // Ensure the image doesn't overflow the container
                                maxHeight: "100%",
                                objectFit: "cover"
                            }}
                            alt="preview"
                        />
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setImg((prev) => ({ ...prev, status: false }))} variant='contained' sx={{ bgcolor: "gray" }} >Cancel</Button>
                </DialogActions>
            </Dialog >

            {/* <CommonModel
                flag={true}
                open={modelOpen}
                handleDialogClose={() => setModelOpen(false)}
                dialogTitle={history.name}
                nvCancel={"close"}
                dialogPara={<Details history={history.data} name={history.name} />}

                yesHandle={() => {
                    setModelOpen(false)
                }}
            /> */}
        </>

    );
}
